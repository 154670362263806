.section {
  background-color: #f9f9fb !important;
  padding-bottom: 4rem;
  padding-top: 4rem;
}

.cta {
  margin-top: 64px;
}

.title {
  text-align: center;
}

.refs {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 3rem;
}

.geekflare {
  margin-right: 64px;
  width: 256px;
}

.producthunt {
  margin-left: 64px;
  width: 256px;
}

.bdm {
  margin-left: 72px;
  width: 256px;
}