/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * proxima-nova:
 *   - http://typekit.com/eulas/00000000000000003b9b3068
 *   - http://typekit.com/eulas/00000000000000003b9b3069
 *   - http://typekit.com/eulas/00000000000000003b9b3062
 *   - http://typekit.com/eulas/00000000000000003b9b3063
 *
 * © 2009-2019 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2019-03-10 04:34:27 UTC"}*/

@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

/* @font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: swap;
  font-style: italic;
  font-weight: 700;
} */

@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

/* @font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: swap;
  font-style: italic;
  font-weight: 400;
} */

/* PostCSS reset */
@import-normalize;

* {
  box-sizing: border-box;
}

body,
html {
  color: #d2d2d6;
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 400;
}

body {
  padding: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: initial;
  font-style: normal;
  font-weight: 200;
  font-family: proxima-nova, Helvetica Neue, sans-serif;
  color: #000;
  color: initial;
  margin: 0;
  margin: initial;
}

p {
  color: #d2d2d6;
  font-size: 1.05em;
}

.ant-form-item:last-child {
  margin-bottom: 0;
}

a[href="https://github.com/Redocly/redoc"]
{
  display: none !important;
}

.medium-zoom-overlay {
  z-index: 1200;
}

.medium-zoom-image {
  z-index: 1201;
}

summary {
  outline: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.swagger-ui {
  max-width: 1200px;
  margin: 0 auto;
}

:root {
  /* TODO add postcss to resolve vars for IE11 */
  --filter-tint: brightness(1.2);
  --color-fg: #3a3a3a;
  --max-width: 1200px;
  --max-width-half: 600px;
  --space-xs: 4px;
  --space-s: 8px;
  --space-m: 16px;
  --space-l: 24px;
  --space-xl: 32px;
  --space-xxl: 48px;
  --space-xxxl: 64px;
  --side-gutter-half: var(--space-m);
  --side-gutter: var(--space-xl);
  --font-family: 'Avenir Next', 'Lato', 'Helvetica Neue', sans-serif;
  --transition-all: all 250ms ease;
  --transition-all-fast: all 125ms ease;

  --line-height-m: 1.375;
  --line-height-l: 1.42;
}

.waves_theme-waves__2XBoC {
  color: #fff;
  font-family: 'Avenir Next', 'Lato', 'Helvetica Neue', sans-serif;
  font-family: var(--font-family);
  font-size: 16px;
  overflow-x: hidden;
}

.waves_theme-waves__2XBoC h1,
.waves_theme-waves__2XBoC h2,
.waves_theme-waves__2XBoC h3,
.waves_theme-waves__2XBoC h4,
.waves_theme-waves__2XBoC h5,
.waves_theme-waves__2XBoC h6 {
  color: #3a3a3a;
  color: var(--color-fg);
  font-family: 'Avenir Next', 'Lato', 'Helvetica Neue', sans-serif;
  font-family: var(--font-family);
  font-weight: 400;
}

.waves_theme-waves__2XBoC p {
  color: #3a3a3a;
  color: var(--color-fg);
}

.waves_theme-waves__2XBoC b,
.waves_theme-waves__2XBoC strong {
  font-weight: 700;
}

.waves_theme-waves__2XBoC .waves_paper__3GQXk {
  border-radius: 8px;
  padding: 24px;
  padding: var(--space-l);
}

.waves_theme-waves__2XBoC .waves_loading__147V_ {
  background: white;
}

.waves_theme-waves__2XBoC .waves_loading__147V_ .waves_loading-icon__3TcHm,
.waves_theme-waves__2XBoC .waves_loading__147V_ .waves_title__3zib4 {
  color: #3a3a3a !important;
  color: var(--color-fg) !important;
}

/*
 * Section
 * ----------------------------
 */

.waves_theme-waves__2XBoC .waves_section__GHThE {
  margin-bottom: 48px;
  margin-bottom: var(--space-xxl);
  margin-top: 48px;
  margin-top: var(--space-xxl);
  padding-left: 32px;
  padding-left: var(--side-gutter);
  padding-right: 32px;
  padding-right: var(--side-gutter);
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_section__GHThE {
    padding-left: 32px;
    padding-left: var(--side-gutter);
    padding-right: 32px;
    padding-right: var(--side-gutter);
  }
}

.waves_theme-waves__2XBoC .waves_section__GHThE .waves_title__3zib4 {
  font-size: 28px;
  position: relative;
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_section__GHThE .waves_title__3zib4 {
    font-size: var(--heading-font-size);
  }
}

.waves_theme-waves__2XBoC .waves_main__TAFQg .waves_section__GHThE .waves_title__3zib4 {
  color: var(--hero-color);
}

.waves_theme-waves__2XBoC .waves_main__TAFQg .waves_section__GHThE .waves_subtitle__1rZIw {
  color: var(--hero-color);
}

.waves_theme-waves__2XBoC .waves_section__GHThE .waves_subtitle__1rZIw {
  color: #3a3a3a;
  color: var(--color-fg);
  font-size: 22px;
  opacity: 0.9;
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_section__GHThE .waves_subtitle__1rZIw {
    font-size: 24px;
  }
}

.waves_theme-waves__2XBoC .waves_section__GHThE .waves_content__1TtQe {
  flex: initial;
  max-width: 1200px;
  max-width: var(--max-width);
  padding: 0 !important;
  z-index: 1;
}

.waves_theme-waves__2XBoC .waves_section-divider__BsI8V {
  display: none;
}

.waves_theme-waves__2XBoC .waves_section__GHThE:last-of-type {
  margin-bottom: calc(2 * 48px);
  margin-bottom: calc(2 * var(--space-xxl));
}

/*
 * Home page
 * ----------------------------
 */

.waves_theme-waves__2XBoC .waves_home-page__TsSWt .waves_hero-section__2Gbug {
  margin-top: 48px;
  margin-top: var(--space-xxl);
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_home-page__TsSWt .waves_hero-section__2Gbug .waves_content__1TtQe,
  .waves_theme-waves__2XBoC .waves_home-page__TsSWt .waves_demo-section__hQfDx .waves_content__1TtQe {
    max-width: 600px;
    max-width: var(--max-width-half);
  }
}

.waves_theme-waves__2XBoC .waves_main__TAFQg {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.waves_theme-waves__2XBoC .waves_main__TAFQg .waves_main-body__5KaFD {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;

  width: 100%;
  height: 100%;
  max-width: 1440px;
  min-height: calc(max(600px, 100vh));
  padding-top: 120px;
}

@media only screen and (max-width: 767px) {
  .waves_theme-waves__2XBoC .waves_main__TAFQg .waves_main-body__5KaFD {
    min-height: calc(max(500px, 100vh - 80px));
    padding-top: 0;
  }
}

/*
 * Home page, Hero section
 * ----------------------------
 */

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_home-page__TsSWt .waves_hero-section__2Gbug {
    padding-right: 0;
  }
}

.waves_theme-waves__2XBoC .waves_home-page__TsSWt .waves_hero-section__2Gbug .waves_content__1TtQe {
  align-items: center;
  justify-content: center;
  flex: 1 1;
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_home-page__TsSWt .waves_hero-section__2Gbug .waves_content__1TtQe {
    align-items: flex-start;
    text-align: left;
    margin-left: auto;
    padding-right: 32px !important;
    padding-right: var(--space-xl) !important;
    z-index: 1;
  }

  .waves_theme-waves__2XBoC .waves_home-page__TsSWt .waves_hero-section__2Gbug .waves_content__1TtQe .waves_title__3zib4,
  .waves_theme-waves__2XBoC .waves_home-page__TsSWt .waves_hero-section__2Gbug .waves_content__1TtQe .waves_subtitle__1rZIw,
  .waves_theme-waves__2XBoC .waves_home-page__TsSWt .waves_hero-section__2Gbug .waves_content__1TtQe .waves_desc__3YBho {
    text-align: left;
  }

  .waves_theme-waves__2XBoC .waves_home-page__TsSWt .waves_main-body-centered__3Mbh0 .waves_hero-section__2Gbug .waves_content__1TtQe {
    align-items: center;
    text-align: center;
  }

  .waves_theme-waves__2XBoC .waves_home-page__TsSWt .waves_main-body-centered__3Mbh0 .waves_hero-section__2Gbug .waves_content__1TtQe .waves_title__3zib4,
  .waves_theme-waves__2XBoC .waves_home-page__TsSWt .waves_main-body-centered__3Mbh0 .waves_hero-section__2Gbug .waves_content__1TtQe .waves_subtitle__1rZIw,
  .waves_theme-waves__2XBoC .waves_home-page__TsSWt .waves_main-body-centered__3Mbh0 .waves_hero-section__2Gbug .waves_content__1TtQe .waves_desc__3YBho {
    text-align: center;
  }
}

.waves_theme-waves__2XBoC .waves_home-page__TsSWt .waves_hero-section__2Gbug .waves_title__3zib4 {
  line-height: 1.375;
  line-height: var(--line-height-m);
  margin: 0;
  margin-bottom: 16px;
  margin-bottom: var(--space-m);
  font-weight: 400;
}

.waves_theme-waves__2XBoC .waves_home-page__TsSWt .waves_hero-section__2Gbug .waves_subtitle__1rZIw {
  line-height: 1.42;
  line-height: var(--line-height-l);
  margin: 0;
  margin-bottom: 24px;
  margin-bottom: var(--space-l);
}

/*
 * Home page, Demo section
 * ----------------------------
 */

.waves_theme-waves__2XBoC .waves_home-page__TsSWt .waves_demo-section__hQfDx {
  display: none;
  background: transparent !important;
  justify-content: center;
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_home-page__TsSWt .waves_demo-section__hQfDx {
    display: flex;
    background: transparent !important;
    padding-left: 0;
  }
}

.waves_theme-waves__2XBoC .waves_home-page__TsSWt .waves_demo-section__hQfDx .waves_content__1TtQe {
  font-weight: 400;
  height: 100%;
  justify-content: center;
  margin-right: auto;
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_home-page__TsSWt .waves_demo-section__hQfDx .waves_content__1TtQe {
    padding-left: 16px !important;
    padding-left: var(--side-gutter-half) !important;
  }
}

.waves_theme-waves__2XBoC .waves_home-page__TsSWt .waves_demo-section__hQfDx .waves_desc__3YBho {
  display: none;
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_home-page__TsSWt .waves_demo-section__hQfDx .waves_title__3zib4 {
    display: none;
  }
}

/*
 * Home page, Features section
 * ----------------------------
 */

.waves_theme-waves__2XBoC .waves_home-page__TsSWt .waves_features-section__2kgv4 .waves_title__3zib4,
.waves_theme-waves__2XBoC .waves_home-page__TsSWt .waves_features-section__2kgv4 .waves_desc__3YBho {
  display: none;
}

.waves_theme-waves__2XBoC .waves_features-section__2kgv4 .waves_features__1c_Zs {
  grid-template-columns: repeat(auto-fill, minmax(256px, 1fr)) !important;
}

.waves_theme-waves__2XBoC .waves_features-section__2kgv4 .waves_feature__MBUP4 {
  margin-left: auto;
  margin-right: auto;
  max-width: 320px;
}

.waves_theme-waves__2XBoC .waves_features-section__2kgv4 .waves_feature__MBUP4 .waves_illustration__1RIN1 {
  max-height: 120px !important;
  width: 220px;
}

.waves_theme-waves__2XBoC .waves_features-section__2kgv4 .waves_feature__MBUP4 h3 {
  font-weight: 600 !important;
}

/*
 * Pricing page
 * ----------------------------
 */

.waves_theme-waves__2XBoC .waves_pricing-page__3tQkN .waves_pricing-section__2mpc4 {
  width: 100%;
  height: auto;
  min-height: 500px;
  z-index: 1;
}

/*
 * Checkout page
 * ----------------------------
 */

.waves_theme-waves__2XBoC .waves_checkout-page__fY6N3 {
  background: #f7f7f7;
  padding: 0;
}

.waves_theme-waves__2XBoC .waves_checkout-page__fY6N3 .box-gallery {
  display: none;
}

.waves_theme-waves__2XBoC .waves_checkout-page__fY6N3 .waves_title__3zib4 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  margin-bottom: var(--space-m);
}

.waves_theme-waves__2XBoC .waves_checkout-page__fY6N3 > .waves_content__1TtQe {
  display: flex;
  align-items: center;
  padding: 16px;
  padding: var(--space-m);
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_checkout-page__fY6N3 > .waves_content__1TtQe {
    justify-content: center;
    flex-direction: row;
    padding-top: 48px;
    padding-top: var(--space-xxl);
  }
}

.waves_theme-waves__2XBoC .waves_checkout-page__fY6N3 .waves_content__1TtQe .waves_form__2806N .ant-form-item {
  text-align: left;
}

.waves_theme-waves__2XBoC .waves_checkout-page__fY6N3 .waves_content__1TtQe .waves_form__2806N .waves_label__3YJn3 {
  font-weight: 500;
}

.waves_theme-waves__2XBoC .waves_checkout-page__fY6N3 .waves_plan__1gLQB {
  margin-bottom: 24px;
  margin-bottom: var(--space-l);
  margin-left: 0;
  margin-right: 0;
}

.waves_theme-waves__2XBoC .waves_plan__1gLQB .waves_name__2_SPA {
  letter-spacing: 4px;
  color: rgba(0, 0, 0, 0.85) !important;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.waves_theme-waves__2XBoC .waves_plan__1gLQB .waves_features__1c_Zs {
  font-size: 0.85em;
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_checkout-page__fY6N3 .waves_plan__1gLQB {
    margin-bottom: 0;
    margin-right: 24px;
    margin-right: var(--space-l);
  }
}

.waves_theme-waves__2XBoC .waves_checkout-page__fY6N3 .waves_form__2806N button {
  min-width: 33%;
}

/*
 * Login page
 * ----------------------------
 */

.waves_theme-waves__2XBoC .waves_login-page__Dn2Kw,
.waves_theme-waves__2XBoC .waves_signup-page__3-fu9 {
  background: #f7f7f7;
}

.waves_theme-waves__2XBoC .waves_login-page__Dn2Kw .waves_body__vIbA9,
.waves_theme-waves__2XBoC .waves_signup-page__3-fu9 .waves_body__vIbA9 {
  max-width: 352px;
}

.waves_theme-waves__2XBoC .waves_login-page__Dn2Kw .waves_title__3zib4,
.waves_theme-waves__2XBoC .waves_signup-page__3-fu9 .waves_title__3zib4 {
  font-weight: 500;
}

.waves_theme-waves__2XBoC .waves_login-page__Dn2Kw .waves_content__1TtQe,
.waves_theme-waves__2XBoC .waves_signup-page__3-fu9 .waves_content__1TtQe {
  background: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  flex-grow: 1;
  text-align: center;
}

.waves_theme-waves__2XBoC .waves_login-page__Dn2Kw .waves_content__1TtQe .waves_form__2806N,
.waves_theme-waves__2XBoC .waves_signup-page__3-fu9 .waves_content__1TtQe .waves_form__2806N {
  margin: 0;
  padding: 0;
  width: 100%;
}

.waves_theme-waves__2XBoC .waves_login-page__Dn2Kw .waves_content__1TtQe .waves_form__2806N > button.waves_submit__3_iqM {
  margin-bottom: 16px;
  margin-bottom: var(--space-m);
}

.waves_theme-waves__2XBoC .waves_login-page__Dn2Kw .waves_content__1TtQe .waves_form__2806N .ant-form-item,
.waves_theme-waves__2XBoC .waves_signup-page__3-fu9 .waves_content__1TtQe .waves_form__2806N .ant-form-item {
  text-align: left;
}

/*
 * Dashboard
 * ----------------------------
 */
.waves_theme-waves__2XBoC .waves_dashboard-page__20R7b,
.waves_theme-waves__2XBoC .waves_dashboard-page__20R7b .waves_section__GHThE {
  background: #f7f7f7 !important;
}

.waves_theme-waves__2XBoC .waves_dashboard-page__20R7b .waves_section__GHThE:first-of-type::before {
  display: none;
}

.waves_theme-waves__2XBoC .waves_dashboard-page__20R7b .waves_dashboard-section__1vp-q .waves_title__3zib4 {
  color: #3a3a3a;
  color: var(--color-fg);
  font-weight: 600;
  font-size: 48px;
}

.waves_theme-waves__2XBoC .waves_dashboard-page__20R7b .waves_section__GHThE:first-of-type::after {
  display: none;
}

.waves_theme-waves__2XBoC .waves_dashboard-page__20R7b .waves_onboarding-section__2WtMk {
  margin-top: 0;
}

.waves_theme-waves__2XBoC .waves_dashboard-page__20R7b .waves_section__GHThE:not(.waves_dashboard-section__1vp-q) .waves_title__3zib4 {
  font-weight: 600;
  font-size: 22px;
}

.waves_theme-waves__2XBoC .waves_dashboard-page__20R7b td button {
  padding: 8px 16px;
  padding: var(--space-s) var(--space-m);
}

/*
 * NavHeader
 * ----------------------------
 */
.waves_theme-waves__2XBoC .waves_nav-header__2DKvZ {
  border-bottom: var(--nav-border-width) solid #e1e1e1;
  padding: 16px;
  padding: var(--side-gutter-half);
  transition: all 125ms ease;
  transition: var(--transition-all-fast);
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_nav-header__2DKvZ {
    padding-bottom: 16px;
    padding-bottom: var(--space-m);
    padding-left: 32px;
    padding-left: var(--side-gutter);
    padding-right: 32px;
    padding-right: var(--side-gutter);
    padding-top: 16px;
    padding-top: var(--space-m);
  }
}

.waves_theme-waves__2XBoC .waves_nav-header__2DKvZ .waves_link__31M76 {
  color: var(--hero-color) !important;
  font-size: 18px;
  font-weight: 500;
  margin-right: 10%;
}

.waves_theme-waves__2XBoC .waves_nav-header__2DKvZ .waves_link__31M76:hover {
  color: var(--color-primary) !important;
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
  -webkit-filter: var(--filter-tint);
          filter: var(--filter-tint);
}

.waves_theme-waves__2XBoC .waves_nav-header__2DKvZ .waves_links__3UlyT .waves_link__31M76:nth-of-type(3) {
  order: 3;
  opacity: 0.9;
}

.waves_theme-waves__2XBoC .waves_nav-header__2DKvZ .waves_links__3UlyT .waves_link__31M76:nth-of-type(1) {
  order: 2;
  opacity: 0.9;
}

.waves_theme-waves__2XBoC .waves_nav-header__2DKvZ .waves_links__3UlyT .waves_link__31M76:nth-of-type(2) {
  order: 1;
  opacity: 0.9;
}

.waves_theme-waves__2XBoC .waves_nav-header__2DKvZ .waves_actions__3-lau {
  justify-content: initial;
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_nav-header__2DKvZ .waves_actions__3-lau {
    justify-content: space-around;
    margin-top: 0;
  }
}

.waves_theme-waves__2XBoC .waves_nav-header__2DKvZ .waves_actions__3-lau .waves_link__31M76:first-of-type {
  margin-left: 0;
  margin-right: 8px;
  margin-right: var(--space-s);
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_nav-header__2DKvZ .waves_actions__3-lau .waves_link__31M76:first-of-type {
    margin-left: 32px;
    margin-left: var(--space-xl);
    margin-right: 0;
  }
}

.waves_theme-waves__2XBoC .waves_nav-header__2DKvZ .waves_actions__3-lau .waves_link__31M76:last-of-type {
  margin-left: 8px;
  margin-left: var(--space-s);
  margin-right: 0;
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_nav-header__2DKvZ .waves_actions__3-lau .waves_link__31M76:last-of-type {
    margin-left: 16px;
    margin-left: var(--space-m);
  }
}

.waves_theme-waves__2XBoC .waves_nav-header__2DKvZ .waves_actions__3-lau .waves_link__31M76,
.waves_theme-waves__2XBoC .waves_nav-header__2DKvZ .waves_actions__3-lau .waves_link__31M76 > button {
  width: 100%;
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_nav-header__2DKvZ .waves_actions__3-lau .waves_link__31M76 {
    margin-left: 16px;
    margin-left: var(--space-m);
    width: auto;
  }
}

.waves_theme-waves__2XBoC header .waves_content__1TtQe {
  max-width: 1200px !important;
  max-width: var(--max-width) !important;
}

.waves_theme-waves__2XBoC header .waves_primary__3--Rg .waves_link__31M76 {
  margin-left: 0;
  margin-right: 0;
}

.waves_theme-waves__2XBoC header.waves_attached__1-f8W {
  background: white !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC header.waves_attached__1-f8W .waves_link__31M76 {
    color: #3a3a3a !important;
    color: var(--color-fg) !important;
  }
}

.waves_theme-waves__2XBoC header .waves_logo__3BY2z {
  max-height: 48px;
}

.waves_theme-waves__2XBoC header:not(.waves_attached__1-f8W) .waves_logo--light__2UfbN {
  display: var(--logo-light-top-display);
}

.waves_theme-waves__2XBoC header:not(.waves_attached__1-f8W) .waves_logo__3BY2z:not(.waves_logo--light__2UfbN) {
  display: var(--logo-top-display);
}

.waves_theme-waves__2XBoC header .waves_logo-text__23DIA {
  font-weight: 600;
}

.waves_theme-waves__2XBoC header.waves_attached__1-f8W .waves_logo-text__23DIA:not(:hover),
.waves_theme-waves__2XBoC header.waves_attached__1-f8W .waves_links__3UlyT .waves_link__31M76:not(:hover),
.waves_theme-waves__2XBoC header.waves_expanded__VYOug .waves_logo-text__23DIA:not(:hover),
.waves_theme-waves__2XBoC header.waves_expanded__VYOug .waves_links__3UlyT .waves_link__31M76:not(:hover) {
  color: #3a3a3a !important;
  color: var(--color-fg) !important;
}

@media only screen and (max-width: 767px) {
  .waves_theme-waves__2XBoC header .waves_logo-text__23DIA:not(:hover),
  .waves_theme-waves__2XBoC header .waves_links__3UlyT .waves_link__31M76:not(:hover),
  .waves_theme-waves__2XBoC header .waves_logo-text__23DIA:not(:hover),
  .waves_theme-waves__2XBoC header .waves_links__3UlyT .waves_link__31M76:not(:hover) {
    color: #3a3a3a !important;
    color: var(--color-fg) !important;
  }
}

/*
 * Footer
 * ----------------------------
 */

.waves_theme-waves__2XBoC footer {
  background-color: #f9f9fb !important;
  border-top: 1px solid #efefef;
}

.waves_theme-waves__2XBoC footer .waves_detail__3iC_3,
.waves_theme-waves__2XBoC footer .waves_header__2ywvW {
  color: #3a3a3a !important;
  color: var(--color-fg) !important;
  opacity: 0.9;
}

.waves_theme-waves__2XBoC footer .waves_link__31M76 {
  color: #3a3a3a !important;
  color: var(--color-fg) !important;
  opacity: 0.9;
}

.waves_theme-waves__2XBoC footer .waves_link__31M76:hover {
  color: var(--color-primary) !important;
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
  -webkit-filter: var(--filter-tint);
          filter: var(--filter-tint);
  opacity: 1;
}

/*
 * CTAs
 * ----------------------------
 */

.waves_theme-waves__2XBoC a {
  transition: all 250ms ease;
  transition: var(--transition-all);
}

.waves_theme-waves__2XBoC a:hover {
  transition: all 250ms ease;
  transition: var(--transition-all);
}

/* TODO: cta-button ghost should have dark text */
.waves_theme-waves__2XBoC .waves_cta-button__2KPHJ,
.waves_theme-waves__2XBoC .waves_cta-button__2KPHJ.waves_inline__3apBN,
.waves_theme-waves__2XBoC .ant-btn {
  border: var(--color-primary) solid 1px !important;
  border-radius: var(--border-radius);
  font-size: 16px !important;
  font-weight: 500;
  height: auto;
  line-height: normal;
  line-height: initial;
  letter-spacing: normal !important;
  text-transform: none !important;
  text-shadow: none;
  padding-bottom: 8px;
  padding-bottom: var(--space-s);
  padding-top: 8px;
  padding-top: var(--space-s);
  padding-left: 32px;
  padding-left: var(--space-xl);
  padding-right: 32px;
  padding-right: var(--space-xl);
  transition: all 250ms ease;
  transition: var(--transition-all);
}

.waves_theme-waves__2XBoC .waves_hero-section__2Gbug .waves_cta-button__2KPHJ,
.waves_theme-waves__2XBoC .waves_cta-section__3NNA5 .waves_cta-button__2KPHJ {
  font-size: 20px !important;
  line-height: 1.5em;
}

.waves_theme-waves__2XBoC .waves_cta-button__2KPHJ.primary,
.waves_theme-waves__2XBoC .ant-btn {
  background: var(--color-primary) !important;
  color: white !important;
}

.waves_theme-waves__2XBoC .waves_cta-button__2KPHJ.secondary,
.waves_theme-waves__2XBoC .ant-btn-secondary {
  background: transparent !important;
  color: var(--color-primary) !important;
}

.waves_theme-waves__2XBoC .waves_docs-page__3kYQl .waves_nav-header__2DKvZ .waves_cta-button__2KPHJ.secondary,
.waves_theme-waves__2XBoC .waves_docs-page__3kYQl .waves_nav-header__2DKvZ .ant-btn-secondary {
  background: transparent !important;
  border-color: #fff !important;
  color: #fff !important;
}

.waves_theme-waves__2XBoC .waves_cta-button__2KPHJ:hover,
.waves_theme-waves__2XBoC .waves_cta-button__2KPHJ:focus,
.waves_theme-waves__2XBoC .ant-btn:hover,
.waves_theme-waves__2XBoC .ant-btn:focus {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.11), 0 3px 6px rgba(0, 0, 0, 0.08);
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
  -webkit-filter: var(--filter-tint);
          filter: var(--filter-tint);
}

.waves_theme-waves__2XBoC .waves_cta-button__2KPHJ.secondary:hover,
.waves_theme-waves__2XBoC .waves_cta-button__2KPHJ.secondary:focus,
.waves_theme-waves__2XBoC .ant-btn-secondary:hover,
.waves_theme-waves__2XBoC .ant-btn-secondary:focus {
  background: var(--color-primary) !important;
  color: #fff !important;
}

.waves_theme-waves__2XBoC.waves_dark-bg__XMTUx
  .waves_nav-header__2DKvZ:not(.waves_expanded__VYOug):not(.waves_attached__1-f8W)
  .waves_cta-button__2KPHJ.secondary,
.waves_theme-waves__2XBoC.waves_dark-bg__XMTUx
  .waves_nav-header__2DKvZ:not(.waves_expanded__VYOug):not(.waves_attached__1-f8W)
  .ant-btn-secondary {
  background: transparent !important;
  border-color: #fff !important;
  color: #fff !important;
}

.waves_theme-waves__2XBoC.waves_dark-bg__XMTUx
  .waves_nav-header__2DKvZ:not(.waves_expanded__VYOug):not(.waves_attached__1-f8W)
  .waves_cta-button__2KPHJ.secondary:hover,
.waves_theme-waves__2XBoC.waves_dark-bg__XMTUx
  .waves_nav-header__2DKvZ:not(.waves_expanded__VYOug):not(.waves_attached__1-f8W)
  .waves_cta-button__2KPHJ.secondary:focus,
.waves_theme-waves__2XBoC.waves_dark-bg__XMTUx
  .waves_nav-header__2DKvZ:not(.waves_expanded__VYOug):not(.waves_attached__1-f8W)
  .ant-btn-secondary:hover,
.waves_theme-waves__2XBoC.waves_dark-bg__XMTUx
  .waves_nav-header__2DKvZ:not(.waves_expanded__VYOug):not(.waves_attached__1-f8W)
  .ant-btn-secondary:focus {
  background: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
  color: #fff !important;
}

.waves_theme-waves__2XBoC .waves_docs-page__3kYQl .waves_nav-header__2DKvZ .waves_cta-button__2KPHJ.secondary:hover,
.waves_theme-waves__2XBoC .waves_docs-page__3kYQl .waves_nav-header__2DKvZ .ant-btn-secondary:hover {
  background: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
  color: #fff !important;
}

.waves_theme-waves__2XBoC .waves_cta-button__2KPHJ:disabled,
.waves_theme-waves__2XBoC .waves_cta-button__2KPHJ:disabled:hover,
.waves_theme-waves__2XBoC .waves_cta-button__2KPHJ.waves_inline__3apBN:disabled,
.waves_theme-waves__2XBoC .waves_cta-button__2KPHJ.waves_inline__3apBN:disabled:hover,
.waves_theme-waves__2XBoC .ant-btn:disabled,
.waves_theme-waves__2XBoC .ant-btn:disabled:hover {
  background-color: #a1a5a9 !important;
  border-color: #a1a5a9 !important;
  color: #fff !important;
  opacity: 0.7;
}

.waves_theme-waves__2XBoC .ant-btn-icon-only {
  width: 40px !important;
  height: 40px !important;
  padding: 8px !important;
}

@media only screen and (max-width: 767px) {
  .waves_theme-waves__2XBoC.waves_dark-bg__XMTUx
    .waves_nav-header__2DKvZ:not(.waves_expanded__VYOug):not(.waves_attached__1-f8W)
    .waves_cta-button__2KPHJ.secondary,
  .waves_theme-waves__2XBoC.waves_dark-bg__XMTUx
    .waves_nav-header__2DKvZ:not(.waves_expanded__VYOug):not(.waves_attached__1-f8W)
    .ant-btn-secondary {
    background: transparent !important;
    border-color: var(--color-primary) !important;
    color: var(--color-primary) !important;
  }

  .waves_theme-waves__2XBoC
    .waves_docs-page__3kYQl
    .waves_nav-header__2DKvZ:not(.waves_expanded__VYOug)
    .waves_cta-button__2KPHJ.secondary,
  .waves_theme-waves__2XBoC
    .waves_docs-page__3kYQl
    .waves_nav-header__2DKvZ:not(.waves_expanded__VYOug)
    .ant-btn-secondary {
    background: transparent !important;
    border-color: #fff !important;
    color: #fff !important;
  }
}

/*
 * Code Block
 * ----------------------------
 */

.waves_theme-waves__2XBoC .waves_live-service-demo__1k6ng {
  background: var(--code-block-background);
  border-radius: 8px;
  box-shadow: none !important;
  -webkit-filter: drop-shadow(0px 3px 6px var(--code-block-shadow-color));
          filter: drop-shadow(0px 3px 6px var(--code-block-shadow-color));
  overflow: hidden;
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_live-service-demo__1k6ng {
    margin-top: 64px;
    margin-top: var(--space-xxxl);
  }
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_live-service-demo__1k6ng .waves_tabs__Tos1N:before {
    align-self: center;
    color: #ffffff90;
    content: 'Live Demo';
    font-size: 16px;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
      monospace;
    white-space: nowrap;
  }
}

.waves_theme-waves__2XBoC .waves_live-service-demo__1k6ng .waves_tabs__Tos1N {
  background: #313b5250;
  color: #ffffff80;
  font-size: 14px;
  padding-bottom: 16px;
  padding-bottom: var(--space-m);
  padding-top: 16px;
  padding-top: var(--space-m);
  padding-left: 24px;
  padding-left: var(--space-l);
  padding-right: 24px;
  padding-right: var(--space-l);
  overflow-x: auto;
}

.waves_theme-waves__2XBoC .waves_live-service-demo__1k6ng .waves_tabs__Tos1N .waves_tab__3JUB2:first-of-type {
  padding-left: 0;
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_live-service-demo__1k6ng .waves_tabs__Tos1N .waves_tab__3JUB2:first-of-type {
    margin-left: auto;
    padding-left: 32px;
    padding-left: var(--space-xl);
  }
}

.waves_theme-waves__2XBoC .waves_live-service-demo__1k6ng .waves_tabs__Tos1N .waves_tab__3JUB2:hover {
  background: transparent;
  color: #ffffff;
}

.waves_theme-waves__2XBoC .waves_live-service-demo__1k6ng .waves_tabs__Tos1N .waves_selected-tab__1G6O4 {
  background: transparent;
  color: #ffffff;
  font-weight: 600;
}

.waves_theme-waves__2XBoC .waves_live-service-demo__1k6ng .waves_tab-content__2fz8U {
  max-height: 32.5vh;
  position: static;
  overflow-y: auto;
}

.waves_theme-waves__2XBoC .waves_live-service-demo__1k6ng .waves_output__1VBh4 {
  max-height: 30vh;
  overflow-y: auto;
}

.waves_theme-waves__2XBoC .waves_live-service-demo__1k6ng .waves_output__1VBh4:not(.waves_output--hit-rate-limit__Je_KS) {
  background: var(--code-block-output-background);
}

.waves_theme-waves__2XBoC .waves_live-service-demo__1k6ng .waves_output__1VBh4 .waves_img-wrapper__3NuTl {
  height: 30vh;
}

.waves_theme-waves__2XBoC .waves_live-service-demo__1k6ng .waves_output--flush__2sCmR .waves_img-wrapper__3NuTl {
  height: auto;
}

.waves_theme-waves__2XBoC .waves_code-block__4ThkV {
  background: transparent !important;
  color: white !important;
  height: auto !important;
  font-size: 14px;
  padding: 16px !important;
  padding: var(--space-m) !important;
  padding-left: 24px !important;
  padding-left: var(--space-l) !important;
  padding-right: 24px !important;
  padding-right: var(--space-l) !important;
}

.waves_theme-waves__2XBoC .waves_live-service-demo__1k6ng .waves_info__2R6Ku,
.waves_theme-waves__2XBoC .waves_live-service-demo__1k6ng .waves_copy__E1ZfY {
  background: white !important;
  border: none !important;
  color: #3a3a3a !important;
  color: var(--color-fg) !important;
  opacity: 0.6;
}

.waves_theme-waves__2XBoC .waves_live-service-demo__1k6ng .waves_copy__E1ZfY:hover {
  opacity: 1;
}

.waves_theme-waves__2XBoC .waves_live-service-demo__1k6ng .ant-btn-secondary:not(:hover) {
  border-color: white !important;
  color: white !important;
}

.waves_theme-waves__2XBoC .waves_live-service-demo__1k6ng .waves_footer__service__path__2G51B {
  color: #9c9c9c;
}

.waves_theme-waves__2XBoC .waves_live-service-demo__1k6ng .waves_footer__service__example-description__-INnd {
  color: #cbcbcb;
}

.waves_theme-waves__2XBoC .waves_live-service-demo__1k6ng .waves_service-form__input-label__2JLHg {
  color: #c7c7c7;
}

/*
 * BG
 * ----------------------------
 */

.waves_theme-waves__2XBoC .waves_main__TAFQg {
  position: relative;

  background-image: var(--background-image);
  background-size: cover;
  background-position: top center;
}

.waves_theme-waves__2XBoC .waves_signup-page__3-fu9::before,
.waves_theme-waves__2XBoC .waves_login-page__Dn2Kw::before,
.waves_theme-waves__2XBoC .waves_checkout-page__fY6N3::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  background-image: var(--background-image);
  background-size: cover;
  background-position: top;
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_main__TAFQg {
    top: -118px;
  }
}

.waves_theme-waves__2XBoC .waves_main__TAFQg::after {
  position: absolute;
  content: '';
  left: 0;
  bottom: -64px;
  width: 100vw;
  height: 260px !important;

  background-image: var(--background-image-wave);
  background-position: center;
  background-size: cover;
}

/*
 * Pricing plans
 * ----------------------------
 */

.waves_theme-waves__2XBoC .waves_pricing-section__2mpc4 {
  margin-top: 0;
}

.waves_theme-waves__2XBoC .waves_pricing-section__2mpc4 .waves_title__3zib4 {
  font-weight: 600;
}

.waves_theme-waves__2XBoC .waves_pricing-section__2mpc4::before {
  height: calc(50% + 0px) !important;
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_pricing-section__2mpc4::before {
    height: calc(50% + 250px) !important;
  }
}

.waves_theme-waves__2XBoC .waves_pricing-section__2mpc4::after {
  top: calc(50% - 225px) !important;
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_pricing-section__2mpc4::after {
    bottom: 150px !important;
    top: auto !important;
  }
}

.waves_theme-waves__2XBoC .waves_pricing-section__2mpc4 .waves_title__3zib4 {
  font-size: 40px;
  line-height: 1.375;
  line-height: var(--line-height-m);
  margin-top: 64px;
  margin-top: var(--space-xxxl);
  margin-bottom: 8px;
  margin-bottom: var(--space-s);
}

.waves_theme-waves__2XBoC .waves_pricing-section__2mpc4 .waves_subtitle__1rZIw {
  font-size: 28px;
  line-height: 1.375;
  line-height: var(--line-height-m);
  margin-bottom: 64px;
  margin-bottom: var(--space-xxxl);
}

.waves_theme-waves__2XBoC .waves_plans__25Mle {
  justify-content: center;
  margin: 0;
  margin-top: 8px;
  margin-top: var(--space-s);
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_plans__25Mle {
    flex-wrap: nowrap;
  }
}

.waves_theme-waves__2XBoC .waves_plan__1gLQB {
  margin-bottom: 48px;
  margin-bottom: var(--space-xxl);
  max-width: 400px;
  width: 100%;
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_plan__1gLQB {
    margin: 24px;
    margin: var(--space-l);
    margin-top: 0;
  }
}

.waves_theme-waves__2XBoC .waves_plan__1gLQB h3 {
  font-size: 28px;
  letter-spacing: normal;
  letter-spacing: initial;
  line-height: 1.375;
  line-height: var(--line-height-m);
  font-weight: 600 !important;
  text-shadow: none;
}

/* Label */
.waves_theme-waves__2XBoC .waves_pricing-section__2mpc4 .waves_plan__1gLQB .waves_pricing__w0JGW .waves_emphasis__2SXdC,
.waves_theme-waves__2XBoC .waves_pricing-section__2mpc4 .waves_plan__1gLQB .waves_pricing__w0JGW .waves_column__2CofG {
  font-weight: 400;
  opacity: 0.6;
  text-align: center;
}

.waves_theme-waves__2XBoC .waves_plan__1gLQB .waves_cta-button__2KPHJ {
  margin-top: 16px;
  margin-top: var(--space-m);
  width: 100%;
}

.waves_theme-waves__2XBoC .waves_plan__1gLQB:first-of-type .waves_cta-button__2KPHJ {
  content: 'test';
}

.waves_theme-waves__2XBoC .waves_plan__1gLQB .waves_price__uvLW0 {
  align-items: baseline;
  color: #9e9e9e;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
}

.waves_theme-waves__2XBoC .waves_plan__1gLQB .waves_price__uvLW0 .waves_dollas__2u5Mj {
  color: #3a3a3a;
  color: var(--color-fg);
  font-size: 28px;
  font-weight: 600;
  margin-right: 8px;
  margin-right: var(--space-s);
}

.waves_theme-waves__2XBoC .waves_enterprise-section__3c4dr .waves_content__1TtQe {
  max-width: 600px;
  text-align: center;
}

/*
 * Docs
 * ----------------------------
 */

.waves_theme-waves__2XBoC .waves_docs-page__3kYQl {
  background: white;
}

.waves_theme-waves__2XBoC .waves_docs-page__3kYQl pre {
  border-radius: 8px;
}

.waves_theme-waves__2XBoC .waves_docs-page__3kYQl header {
  background: #292929 !important;
  border-bottom: none;
}

@media (min-width: 48em) {
  .waves_theme-waves__2XBoC .waves_docs-page__3kYQl header.waves_attached__1-f8W .waves_link__31M76 {
    color: white !important;
  }
}

.waves_theme-waves__2XBoC .waves_docs-page__3kYQl header .waves_cta-button__2KPHJ.secondary:not(:hover) {
  border-color: white !important;
  color: white !important;
}

.waves_theme-waves__2XBoC .waves_docs-page__3kYQl header .waves_logo-text__23DIA:not(:hover),
.waves_theme-waves__2XBoC .waves_docs-page__3kYQl header .waves_links__3UlyT .waves_link__31M76:not(:hover) {
  color: white !important;
}

.waves_theme-waves__2XBoC .waves_docs-page__3kYQl .waves_logo--light__2UfbN {
  display: flex !important;
}

.waves_theme-waves__2XBoC .waves_docs-page__3kYQl .waves_nav-header__2DKvZ .waves_logo__3BY2z:not(.waves_logo--light__2UfbN) {
  display: none !important;
}

.waves_theme-waves__2XBoC .waves_docs-page__3kYQl p {
  font-family: 'Avenir Next', 'Lato', 'Helvetica Neue', sans-serif;
  font-family: var(--font-family);
}

.styles_banner__LHic5 {
    background-color: black;
    height: 32px;
    left: 0;
    line-height: 32px;
    text-align: center;
    position: -webkit-sticky;
    position: sticky;
    top: 78px;
    width: 100%;
    z-index: 20;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  }
  
  .styles_banner__LHic5 a {
    color: #EA4293
  }
.styles_image__1FdRX {
  max-width: 100%;
  width: 640px;
  margin-bottom: 4em;
  border-radius: 8px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
}

.styles_service-form__input-wrapper__1zXjP {
  margin-bottom: 8px;
}

.styles_service-form__input-wrapper__1zXjP:last-of-type {
  margin-bottom: 0px;
}

.styles_service-form__input-label__L3mZR {
  color: #4a4a4a;
  font-size: 16px;
  margin-bottom: 4px;
}

.styles_service-form__input-name__K4PSw {
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
    monospace;
  font-weight: bold;
}

.styles_service-form__2fo0Z .ant-select-selection__rendered {
  margin-right: 0;
}

.styles_service-form__2fo0Z .ant-select-search__field {
  border: 0;
  padding-left: 0;
}

.styles_service-form__2fo0Z .npm__react-simple-code-editor__textarea {
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
    monospace !important;
  padding: 0.5em !important;
  z-index: 10;
}

.styles_service-form__2fo0Z .npm__react-simple-code-editor__textarea::selection {
  background: #4e95dc62;
}

.styles_service-form__2fo0Z .npm__react-simple-code-editor__textarea + pre > pre {
  margin-bottom: 0;
}

.styles_live-service-demo__WTzD2 {
  background: white;
  position: relative;
  width: 650px;
  max-width: 100%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px;
  border-radius: 8px;
  overflow: hidden;
}

.styles_tabs__1GBhJ {
  background: #dbdbdb;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  display: flex;
  flex-direction: row;

  color: #333;

  font-size: 0.9em;
  padding: 0 2em;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.styles_tab__3YjYw {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  cursor: pointer;
  padding: 0.3em 1em;
  background: transparent;
}

.styles_tab__3YjYw:hover {
  background: #ebebeb;
}

.styles_selected-tab__qSsF_ {
  background: #fff;
}

.styles_selected-tab__qSsF_:hover {
  background: #f5f2f0;
}

.styles_tab-content__mkHbr {
  position: relative;
  font-size: 0.85em;
}

.styles_tab-pane__1mI29 {
  display: none;
  cursor: text;
}

.styles_selected-tab-pane__3EvLl {
  display: block;
}

.styles_code__3-TaF {
  height: 396px;
  overflow: hidden;
  overflow-x: auto;
}

.styles_icons__1mVxc {
  position: absolute;
  bottom: 1em;
  right: 1em;
}

.styles_run__1S1Pt {
  margin-right: 1em;
}

.styles_info__tuLMi {
  margin-right: 1em;
  cursor: pointer;
}

.styles_copy__t-Dun {
  height: 100% !important;
}

.styles_output__27CxV .styles_output__cta__3OKuX {
  padding: 24px;
  text-align: center;
}

.styles_output__27CxV .styles_output__cta__message__1vdj7 {
  padding-bottom: 16px;
}

.styles_output__27CxV .styles_error__2mrP8 {
  color: #e22301;
  font-weight: 600;
  padding: 24px;
  text-align: center;
}

.styles_output__27CxV .styles_message__31pIR {
  font-weight: 600;
  padding: 24px;
  text-align: center;
}

.styles_output__27CxV .styles_img-wrapper__bMq-e {
  padding: 24px;
}

.styles_output--flush__pDPhT .styles_img-wrapper__bMq-e {
  padding: 0;
}

.styles_output__27CxV img {
  display: block;
  max-height: 100%;
  max-width: 100%;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
  .styles_code__3-TaF {
    height: 20em;
  }
}

.styles_api-playground__xfVGl {
  padding: 24px;
  padding-bottom: 28px;
  padding-top: 20px;
}

.styles_footer__2APBR {
  align-items: center;
  background-color: #49587950;
  display: flex;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
}

.styles_footer__service__2qoCz {
  flex-grow: 1;
  margin-top: -4px;
  margin-right: var(--space-s);
  padding-top: 16px;
}

.styles_footer__service__path__2spws {
  align-items: center;
  color: #4a4a4a;
  display: flex;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
    monospace;
  line-height: 1;
}

.styles_footer__service__name__1ZlOf {
  color: #ce9f89;
  font-weight: bold;
}

.styles_footer__actions__1LF9z {
  display: flex;
  padding-top: 16px;
}

.styles_footer__action__23jz5 {
  margin-left: var(--space-s);
}

.styles_footer__action__23jz5:first-of-type {
  margin-left: 0;
}

.styles_footer__service__badge__rniqQ {
  border-radius: 4px;
  color: white;
  margin-right: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  padding-bottom: 4px;
}

.styles_footer__service__badge--GET__2ld3f {
  background: #9bd092;
}

.styles_footer__service__badge--POST__1Wt1E {
  background: #508fcb;
}

.styles_footer__service__example-description__2y7-u {
  color: #4a4a4a;
  font-size: 12px;
  margin-bottom: 4px;
  text-transform: uppercase;
  font-weight: 600;
}

.styles_features__1Xo4q {
  display: grid;
  grid-gap: 4em;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.styles_feature__ejHG6 {
  text-align: center;
}

.styles_feature__ejHG6 h3 {
  font-size: 1.3em;
  font-weight: 500;
}

.styles_illustration__2AUgh {
  width: 192px;
  max-height: 72px;
  margin-bottom: 1em;
}

.styles_section__1mQJB {
  background-color: #f9f9fb !important;
  padding-bottom: 4rem;
  padding-top: 4rem;
}

.styles_cta__3CA1A {
  margin-top: 64px;
}

.styles_title__3CwxN {
  text-align: center;
}

.styles_refs__1yt7- {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 3rem;
}

.styles_geekflare__1bGbl {
  margin-right: 64px;
  width: 256px;
}

.styles_producthunt__3DlfZ {
  margin-left: 64px;
  width: 256px;
}

.styles_bdm__3Eguz {
  margin-left: 72px;
  width: 256px;
}
.styles_subtitle__200bt {
  font-size: medium;
}

.styles_pricing__1myVo {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  text-align: left;
}

.styles_column-content__1AZsh {
  text-align: center;
}

.styles_infinity__2qtT6 {
  height: 1.5em;
}

.styles_plan__3TYF0 {
  max-width: 420px !important;
}

.styles_plans__2YMqK {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 2em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

@media only screen and (max-width: 767px) {
  .styles_plan__3TYF0:not(first-child) {
    margin-top: 1em;
  }
}

.styles_currency__3WJnk {
  color: var(--hero-color);
  display: flex;
  justify-content: flex-end;
}

.styles_reply__238zI {
  text-align: center;
}

.styles_body__2NCHE {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 1200px;
  width: 100%;
}

.styles_tokenButton__ClwsL {
  display: inline-block;
  margin: 1em 1em 0 0;
}

.styles_result__3fjQr {
  padding-top: 0;
}

.styles_steps__ozmRg {
  max-width: 720px;
  margin: 0 auto;
}

.styles_body__2NCHE .ant-result-icon {
  display: none;
}

.styles_apiTokenWrapper__1JV9N {
  display: flex;
  justify-content: center;
}

.styles_code__2m-dm {
  padding: 1em;
  text-align: center;
}

.styles_error__1u3qB > div > h5 {
  display: inline-block;
  max-width: 800px !important;
}

.styles_illustration__1TbAF {
  min-width: 128px;
  max-width: 360px;
}

.styles_desc__2H170 {
  font-size: 0.9em;
}

.styles_detail__24jfh {
  font-size: 0.9em;
}

.styles_container__2EMgt {
  max-width: 840px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.styles_paragraph__1-_Kb {
  margin-bottom: 0;
  margin-top: 0;
}

.styles_pic__1-UOP {
  border: 1px var(--color-primary) solid;
  border-radius: 50%;
  margin-right: 2rem;
  height: 7rem;
  width: 7rem;
}
.styles_home-page__3SRAr {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.styles_docs-page__2kPGP {
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.styles_docs-page__2kPGP .api-content {
  border-left: 1px solid #e2e2e2;
}

.styles_docs-page__2kPGP .api-content > div {
  border-bottom: 1px solid #e2e2e2;
  padding: 0;
  padding-bottom: 22px;
  padding-top: 24px;
}

.styles_docs-page__2kPGP .api-content > div > * {
  margin-bottom: 8px;
}

.styles_docs-page__2kPGP .api-content > div > *:last-of-type {
  margin-bottom: 0;
}

.styles_docs-page__2kPGP .api-content > div:last-of-type {
  border-bottom: 0;
}

.styles_docs-page__2kPGP p {
  font-size: 15px;
  color: #333;
}

.styles_docs-page__2kPGP .api-content .react-tabs__tab-panel p {
  color: #d2d2d6 !important;
}

.styles_docs-page__2kPGP h1,
.styles_docs-page__2kPGP h2,
.styles_docs-page__2kPGP h3,
.styles_docs-page__2kPGP h4,
.styles_docs-page__2kPGP h5 {
  margin: 0;
  font-weight: 600;
}

.styles_docs-page__2kPGP h3,
.styles_docs-page__2kPGP h4,
.styles_docs-page__2kPGP h5 {
  margin-bottom: 8px;
}

.styles_docs-page__2kPGP div[data-section-id^='operation/'] > div > div:nth-of-type(2) h3 {
  color: #fff;
}

.styles_docs-page__2kPGP div[data-section-id^='operation/'] h5 {
  margin: 0;
}

.styles_docs-page__2kPGP .react-tabs__tab-panel > div > pre {
  overflow: hidden;
}

.styles_docs-page__2kPGP .api-content > div:first-of-type {
  display: none;
}

.styles_docs-page__2kPGP .react-tabs__tab-panel {
  border-radius: 5px;
}

.styles_docs-page__2kPGP div[data-section-id^='operation/'] h3 {
  color: black !important;
  margin-top: 24px;
}

.styles_docs-page__2kPGP .hiuczA:last-child {
  min-height: 0;
  min-height: initial;
  margin-bottom: 4em;
}

.styles_fill__3rI8r {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.styles_terms-page__1UKza {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.styles_content__3SJpw {
  padding: 40px 0;
  width: 80%;
  min-width: 250px;
  max-width: 1280px;
  margin: 0 auto;
  color: #222;
}

.styles_content__3SJpw h1 {
  font-size: 2.4em;
  margin-bottom: 0.25em;
}

.styles_content__3SJpw h2 {
  font-size: 2em;
  margin-bottom: 1.3em;
}

.styles_content__3SJpw h3 {
  font-size: 1.6em;
  margin-bottom: 1.1em;
}

.styles_content__3SJpw h4 {
  font-size: 1.2em;
}

.styles_content__3SJpw p,
.styles_content__3SJpw h4,
.styles_content__3SJpw ul {
  margin-bottom: 0.8em;
}

.styles_content__3SJpw .styles_last-modified__1OeBb {
  margin-bottom: 1.6em;
}

.styles_content__3SJpw ul {
  margin-left: 32px;
}

.styles_privacy-page__2FZ74 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.styles_content__r_xxv {
  padding: 2em 0;
  width: 80%;
  min-width: 250px;
  max-width: 1280px;
  margin: 0 auto;
  color: #222;
}

.styles_content__r_xxv h1 {
  font-size: 2.4em;
  margin-bottom: 0.25em;
}

.styles_content__r_xxv h2 {
  font-size: 2em;
  margin-bottom: 1.3em;
}

.styles_content__r_xxv h3 {
  font-size: 1.6em;
  margin-bottom: 1.1em;
}

.styles_content__r_xxv h4 {
  font-size: 1.2em;
}

.styles_content__r_xxv p,
.styles_content__r_xxv h4,
.styles_content__r_xxv ul {
  margin-bottom: 0.8em;
}

.styles_content__r_xxv .styles_last-modified__X48Xi {
  margin-bottom: 1.6em;
}

.styles_content__r_xxv ul {
  margin-left: 32px;
}

.styles_not-found-page__2jNyU {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.styles_login-page__15k55 {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.styles_content__2o3Ur {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.styles_body__3B6wT {
  position: relative;
  z-index: 100;

  width: 100%;
  max-width: 420px;
}

.styles_migration__3Y7Av {
  padding: 0.5rem;
  background-color: #fcdbd3;
  margin-bottom: 1rem;
}

.styles_signup-page__2vhEr {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.styles_content__WxwuC {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.styles_body__wWVYS {
  position: relative;
  z-index: 100;

  width: 100%;
  max-width: 420px;
}

.styles_checkout-page__1fzHB {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 66px 0 0;
}

.styles_content__O5ZHk {
  position: relative;
  z-index: 100;

  display: grid;
  grid-template-columns: 1fr 1.5fr;
  grid-template-rows: 1fr;
  grid-column-gap: 2em;
  grid-row-gap: 2em;

  padding: 2em 2em;
}

.styles_checkout-form__3deAb {
  width: 100%;
  min-width: 320px;
  max-width: 600px;
}

.styles_dashboard-page__1datS {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.styles_account-page__1oT4w {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

