.container {
  max-width: 840px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paragraph {
  margin-bottom: 0;
  margin-top: 0;
}

.pic {
  border: 1px var(--color-primary) solid;
  border-radius: 50%;
  margin-right: 2rem;
  height: 7rem;
  width: 7rem;
}