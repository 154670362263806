.service-form__input-wrapper {
  margin-bottom: 8px;
}

.service-form__input-wrapper:last-of-type {
  margin-bottom: 0px;
}

.service-form__input-label {
  color: #4a4a4a;
  font-size: 16px;
  margin-bottom: 4px;
}

.service-form__input-name {
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
    monospace;
  font-weight: bold;
}

.service-form :global(.ant-select-selection__rendered) {
  margin-right: 0;
}

.service-form :global(.ant-select-search__field) {
  border: 0;
  padding-left: 0;
}

.service-form :global(.npm__react-simple-code-editor__textarea) {
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
    monospace !important;
  padding: 0.5em !important;
  z-index: 10;
}

.service-form :global(.npm__react-simple-code-editor__textarea)::selection {
  background: #4e95dc62;
}

.service-form :global(.npm__react-simple-code-editor__textarea) + pre > pre {
  margin-bottom: 0;
}
